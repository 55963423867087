<template>
  <b-card title="Create Purchase Order">
    <b-modal v-model="disableButton" centered no-close-on-backdrop hide-footer hide-header>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Processing Purchase Order</p>
      </div>
    </b-modal>
    <b-col cols="12">

      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent="">
            <!--temporary hardcode only show this form select when current dealer not found-->
            <b-form-group v-if="user.roleRef.name === 'Super Admin'">
              <span>Dealer <span style="color:red">(*)</span></span>
              <v-select
                v-if="!getDealer"
                v-model="dealerChoice"
                placeholder="----- Select Dealer -----"
                :options="dealer"
                label="dealerName"
                @input="defineDealer"
                :clearable="false"
                style="margin-top:5px !important;"
                class="mb-1"
              />
              <strong v-if="!getDealer" style="color:red;"
                >Please Select Dealer first</strong
              >
            </b-form-group>
            <b-row
              v-if="getDealer && user.roleRef.name === 'Super Admin'"
              style="margin-bottom:10px !important; margin-left:5px !important;"
            >
              <b-col cols="6">
                <b-row> Dealer Name : {{ dealerChoice.dealerName }}</b-row>
                <b-row> Industry Type : {{ dealerChoice.industryType }}</b-row>
                <b-row> Second Industry Type : {{ dealerChoice.secondIndustryType }}</b-row>
              </b-col>
              <b-col cols="2">
                <b-button variant="danger" size="sm" @click="emptyDealer"
                  >X</b-button
                >
              </b-col>
            </b-row>

            <b-form-group>
              <span>Industry Type<span style="color:red">(*)</span></span>
              <v-select
                v-model="selectedIndustryType"
                placeholder="----- Select Industry Types -----"
                :options="industryTypeOption"
                @input="defineIndustryType"
                :clearable="false"
                style="margin-top:5px !important;"
                class="mb-1"
              >
              </v-select>
            </b-form-group>

            <b-form-group>
              <span>Fulfilment Type <span style="color:red">(*)</span></span>
              <b-form-select
                v-model="form.orderType"
                style="margin-top:5px !important;"
                :options="orderTypes"
                @input="cleanOrderType"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group v-if="form.orderType === 'SELF_COLLECT_AMK'">
              <span>Collection address at Daikin Office</span>
              <b-form-input
                style="margin-top:5px !important;"
                type="text"
                value="10 Ang Mo Kio Industrial Park 2, Singapore 569501"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group v-if="form.orderType === 'SELF_COLLECT_SUMITOMO'">
              <span>Collection address at Daikin Office</span>
              <b-form-input
                style="margin-top:5px !important;"
                type="text"
                value="38 Tuas West Rd, Singapore 638385"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group v-if="form.orderType === 'DELIVERY'">
              <span>Delivery Type <span style="color:red">(*)</span></span>
              <b-form-select
                v-model="form.deliveryType"
                style="margin-top:5px !important;"
                :options="deliveryTypeOptions"
                @input="cleanUpDelivery"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group
              v-if="
                form.orderType === 'DELIVERY' && form.deliveryType === 'SOWH'
              "
            >
              <span>Address Type <span style="color:red">(*)</span></span>
              <b-form-select
                v-model="form.addressType"
                style="margin-top:5px !important;"
                :options="optionAddress"
                @input="autoFillDealer"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group>
              <span
                v-if="
                  form.orderType === 'SELF_COLLECT_AMK' ||
                    form.orderType === 'SELF_COLLECT_SUMITOMO'
                "
                >Collection Date <span style="color:red">(*)</span></span
              >
              <span v-if="form.orderType === 'DELIVERY'"
                >Requested Delivery Date<span style="color:red">(*)</span></span
              ><br />
              <date-picker
                v-model="form.deliveryDate"
                style="margin-top:5px !important;"
                :disabled-date="filterDisableDate"
                type="date"
                :use12h="true"
                :show-second="false"
                @pick="datePicked"
              />
            </b-form-group>

            <b-form-group>
              <span>Timeslot <span style="color:red">(*)</span></span
              ><br />
              <b-form-select
                style="width: 210px; margin-top:5px !important;"
                v-model="form.timeslot"
                :options="timeslotOptions"
              >
                <b-form-select-option :value="null" disabled
                  >-- Please select timeslot --</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <!--section displays to order item-->
            <div class="cart__area">
              <div class="card__area-body">
                <div class="cart__area-columns mb-1">
                  <span> Item<span style="color:red">(*)</span> </span>
                  <span>
                    Quantity
                  </span>
                  <span>
                    Unit Price
                  </span>
                  <span>
                    Total Price
                  </span>
                  <span> </span>
                </div>

                <div
                  class="cart__area-columns"
                  :key="index"
                  v-for="(cartItem, index) in form.items"
                >
                  <v-select
                    :disabled="!industryTypeValid"
                    class="style-chooser"
                    @option:selected="
                      (selected) => fillItemInfoForm(index, selected, cartItem)
                    "
                    v-model="cartItem.itemModel"
                    @search="ajaxSearch"
                    :options="searchItems"
                    :clearSearchOnSelect="true"
                  >
                    <template #option="{ description,itemModel }">
                      {{ itemModel }}
                      <br />
                      <cite style="font-size:8px">{{ description }}</cite>
                    </template>
                    <template
                      slot="selected-option"
                      :slot-scope="option"
                      style="font-size:10px"
                    >
                      <cite style="font-size:10px">{{
                        `${cartItem.itemModel} - ${cartItem.description}`
                      }}</cite>
                    </template>
                  </v-select>

                  <b-form-input
                    v-model="cartItem.qty"
                    type="number"
                    placeholder="Enter Qty"
                    @change="(payload) => changeItemInfoQty(index, cartItem)"
                  ></b-form-input>

                  <b-form-input
                    :value="formatter(cartItem.price)"
                    type="number"
                    style="text-align: right;"
                    placeholder="Enter Price"
                    disabled
                  ></b-form-input>

                  <b-form-input
                    type="number"
                    :value="formatter(cartItem.totalPrice)"
                    placeholder="Total Price"
                    style="text-align: right;"
                    disabled
                  ></b-form-input>

                  <b-button
                    variant="danger"
                    @click="cartRemoveItem(index)"
                    :disabled="!industryTypeValid"
                  >
                    <b-icon-trash />
                  </b-button>
                </div>

                <!-- ======================================================= -->
                <div class="cart__area_mobile">
                  <div class="card__area-body_mobile">
                    <div class="cart__area-columns_mobile mb-1_mobile">
                      <b-row
                        :key="index"
                        v-for="(cartItem, index) in form.items"
                      >
                        <b-col cols="12">
                          <b-row style="margin-bottom:10px">
                            <b-col cols="12">
                              <b-label
                                >Item
                                <span style="color:red">(*)</span></b-label
                              >
                              <v-select
                                :disabled="!industryTypeValid"
                                :hint="
                                  `${cartItem.itemModel} - ${cartItem.description}`
                                "
                                style="font-size:10px"
                                @option:selected="
                                  (selected) =>
                                    fillItemInfoForm(index, selected, cartItem)
                                "
                                v-model="cartItem.itemModel"
                                @search="ajaxSearch"
                                :options="searchItems"
                              >
                                <template
                                  slot="selected-option"
                                  :slot-scope="option"
                                  >{{
                                    `${cartItem.itemModel} - ${cartItem.description}`
                                  }}</template
                                >
                              </v-select>
                            </b-col>
                          </b-row>
                          <b-row style="margin-top:10px">
                            <b-col cols="3">
                              <b-form-input
                                v-model="cartItem.qty"
                                type="text"
                                placeholder="Enter Qty"
                                @change="
                                  (payload) =>
                                    changeItemInfoQty(index, cartItem)
                                "
                              ></b-form-input>
                            </b-col>
                            <b-col cols="3">
                              <b-form-input
                                :value="formatter(cartItem.price)"
                                type="text"
                                placeholder="Enter Price"
                                disabled
                              ></b-form-input>
                            </b-col>
                            <b-col cols="3">
                              <b-form-input
                                type="text"
                                :value="formatter(cartItem.totalPrice)"
                                placeholder="Total Price"
                                disabled
                              ></b-form-input>
                            </b-col>
                            <b-col cols="3">
                              <b-button
                                @click="cartRemoveItem(index)"
                                variant="danger"
                                :disabled="!industryTypeValid"
                              >
                                <b-icon-trash />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>

                    <!-- =================================================== -->

                    <div class="cart__area-info mt-3">
                      <div class="cart__area-line-info">
                        <span>
                          Subtotal
                        </span>

                        <b-form-input
                          :value="formatter(cartTotalPrice)"
                          placeholder="Total Price"
                          disabled
                          style="text-align: right;"
                        ></b-form-input>
                      </div>

                      <div class="cart__area-line-info">
                        <span>
                          GST {{ currentGST.value }}%
                        </span>

                        <b-form-input
                          :value="formatter(gstTotal)"
                          placeholder="GST"
                          disabled
                          style="text-align: right;"
                        ></b-form-input>
                      </div>

                      <div class="cart__area-line-info">
                        <span>
                          Total Price
                        </span>

                        <b-form-input
                          :value="formatter(cartTotal)"
                          placeholder="Cart Total"
                          disabled
                          style="text-align: right;"
                        ></b-form-input>
                      </div>
                    </div>

                    <!--section displays a check stock error-->
                    <div
                      ref="errorPage"
                      tabindex="-1"
                      id="stock-error"
                      class="alert alert-danger py-1"
                      v-if="stockError.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(error, index) in Object.values(stockError)"
                          :key="index"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                    <!--END section displays a check stock error-->

                    <b-button
                      @click="cartAddItem"
                      class="my-2"
                      variant="outline-success"
                      :disabled="!industryTypeValid"
                    >
                      Add Item
                    </b-button>

                    <b-button
                      @click="showBundleModal = true"
                      class="ml-2"
                      variant="outline-success"
                      :disabled="!industryTypeValid"
                    >
                      Add Set
                    </b-button>

                    <!--choose item bundle from modal pop-up-->
                    <b-modal v-model="showBundleModal" title="Choose Bundle">
                      <b-container>
                        <b-row>
                          <b-col sm="3">
                            Bundle Set
                          </b-col>
                          <b-col cols="9">
                            <b-form-select v-model="selectedBundle">
                              <option
                                v-for="(selectOption, indexOpt) in filterBundle"
                                :key="indexOpt"
                                :value="selectOption"
                              >
                                {{ selectOption.name }}
                              </option>
                            </b-form-select>
                          </b-col>
                          
                        </b-row>
                        <b-row>
                          <b-col sm="3">
                            Bundle Quantity
                          </b-col>
                          <b-col cols="9">
                            <b-form-input
                              v-model="bundleQuantity"
                              :type="number"
                              placeholder="Enter Bundle Qty"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                      </b-container>

                      <template #modal-footer>
                        <b-button variant="success" @click="cartAddBundle">
                          Confirm
                        </b-button>

                        <b-button
                          variant="danger"
                          @click="showBundleModal = false"
                        >
                          Cancel
                        </b-button>
                      </template>
                    </b-modal>
                    <!--END choose item bundle from modal pop-up-->
                  </div>
                </div>
              </div>
            </div>
            <!--END section displays to order item-->

            <div>
              <!--
              <b-form-group v-if="form.deliveryType !== 'SOWH'">
                <span
                  >Company Name
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.companyName"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Enter Company Name"
                ></b-form-input>
              </b-form-group>
              -->
              
              <b-form-group v-if="form.deliveryType !== 'SOWH'|| form.addressType!='Default Address'">
              
                <span
                  >Name
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  maxlength="40"
                  v-model="form.customer.firstName"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group>

              <!--
              <b-form-group
                v-if="
                  form.orderType === 'DELIVERY' && form.deliveryType === 'SOEC'
                "
              >
                <span
                  >Last Name
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.lastName"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Name Last"
                ></b-form-input>
              </b-form-group>
              -->

              <b-form-group>
                <span
                  >Phone
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.phone"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Enter Phone"
                  :disabled="chooseAddress"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <span
                  >Email
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.email"
                  style="margin-top:5px !important;"
                  type="email"
                  placeholder="Enter Email"
                  :disabled="chooseAddress"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
              
                <span
                  >Postal
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                ><br />
                <b-row>
                  <b-col md="9" cols="6">
                    <b-form-input
                      v-model="form.customer.postal"
                      type="text"
                      maxlength="6"
                      placeholder="Enter Postal"
                      :disabled="chooseAddress"
                    ></b-form-input>
                  </b-col>
                  <b-col md="3" cols="6">
                    <b-button
                    :disabled="chooseAddress"
                    @click="addAddressByPostal(form.customer.postal)"
                    variant="success"
                    class="mr-1"
                    :disable="disableButton"
                    >Auto Fill Address</b-button>
                  </b-col>
                </b-row>  
              </b-form-group>

              <b-form-group>
                <span
                  >Delivery Street Name
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.street"
                  style="margin-top:5px !important;"
                  type="text"
                  maxlength="27"
                  placeholder="Enter Delivery Street"
                  :disabled="chooseAddress"
                ></b-form-input>
              </b-form-group>

              <b-form-group v-if="mandatoryStatus">
                <span
                  >Delivery Block No
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.block"
                  style="margin-top:5px !important;"
                  type="text"
                  maxlength="4"
                  placeholder="Delivery Block No"
                  :disabled="chooseAddress"
                ></b-form-input>
              </b-form-group>

              <b-form-group v-if="mandatoryStatus">
                <span
                  >Delivery Unit No
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-col cols="12" style="padding-left:0px; padding-right:0px">
                  <div
                    style="margin-top:5px !important; display:flex;margin-right:0px; "
                  >
                    <!-- <b-row style="margin-top:5px !important; display:flex;margin-right:0px"> -->
                    <!-- <b-col cols="12"> -->
                    <div style="margin-right:10px;">
                      <strong>#</strong>
                    </div>
                    <div class=" deliveryUnitNo">
                      <b-form-input
                        style="float:right"
                        v-model="form.customer.floor"
                        type="text"
                        maxlength="3"
                        :disabled="chooseAddress"
                        placeholder="Floor"
                      ></b-form-input>
                    </div>
                    <div style="width:20px"></div>
                    <!-- <b-col cols="1"></b-col> -->
                    <div style="margin-right:10px;"><strong>-</strong></div>
                    <div class="deliveryUnitNo">
                      <b-form-input
                        v-model="form.customer.number"
                        type="text"
                        maxlength="4"
                        placeholder="Number"
                        :disabled="chooseAddress"
                      ></b-form-input>
                    </div>

                    <!-- </b-col> -->
                    <!-- </b-col> -->

                    <!-- <b-col cols="1">
                  <strong>-</strong>
                  </b-col>
                  <b-col cols="5">
                  <b-form-input
                    v-model="form.customer.number"
                    type="text"
                    maxlength=4
                    placeholder="Number"
                    :disabled="chooseAddress"
                  ></b-form-input>
                  </b-col> -->
                    <!-- </b-col> -->
                    <!-- </b-row> -->
                  </div>
                </b-col>
              </b-form-group>

              <b-form-group >
                <span
                  >Building Name
                  <span v-if="mandatoryStatus" style="color:red"
                    >(*)</span
                  ></span
                >
                <b-form-input
                  v-model="form.customer.buildingName"
                  style="margin-top:5px !important;"
                  type="text"
                  maxlength="30"
                  placeholder="Enter Building Name"
                  :disabled="chooseAddress"
                ></b-form-input>
              </b-form-group>

              <b-form-group v-if="mandatoryStatus">
                <span>Delivery Instruction</span>
                <b-form-input
                  maxlength="28"
                  :disabled="chooseAddress"
                  v-model="form.deliveryInstruction"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Delivery Instruction"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <span>City<span style="color:red">(*)</span></span>
                <b-form-input
                  v-model="form.customer.city"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="City"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <span>Customer PO<span style="color:red">(*)</span></span>
                <b-form-input
                v-model="form.customerPo"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Customer PO"
                maxlength="23"
                 @input.native="validateInput"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <span>Remarks<span style="color:red">(*) Can input up to 155 characters</span></span>
                <b-form-textarea
                v-model="form.remarks"
                style="margin-top:5px !important;"
                placeholder="Remarks"
                rows="4"
                max-rows="10"
                @input.native="remarksMaxHandler"
                ></b-form-textarea>
              </b-form-group>

            </div>

            <b-button
              @click="promptDraft=true"
              type="submit"
              variant="outline-primary"
              class="mr-1"
              :disable="disableButton"
              >Save Draft</b-button
            >
            <b-button
              @click="addSaleBtn"
              type="submit"
              variant="success"
              class="mr-1"
              :disable="disableButton"
              >Confirm Order</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </b-col>

    <!--if confirm order failed, will show this modal to guide save draft -->
    <b-modal ref="modal" v-model="promptDraft" title="Confirm Save Draft">
      <p class="my-2">
        Save Purchase Order as draft ?
      </p>
      <template #modal-footer>
        <b-button size="md" variant="success" @click="saveAsDraft">
          Yes
        </b-button>
        <b-button size="md" variant="danger" @click="promptDraft = false">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "@/axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import lodash from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { sleep } from "@/utils/utils.js";
// import { isRaw } from "@vue/composition-api";

export default {
  components: {
    "date-picker": DatePicker,
    "v-select": vSelect,
  },
  data() {
    return {
      //form to save data
      form: {
        deliveryType: "SOSC", //default form will be self collect
        customer: {
          type: "Individual",
          companyName: "",
          firstName: "",
          lastName: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          phone: "",
          email: "",
          postal: "",
          city: "Singapore",
          //detail address
          street: "",
          block: "",
          floor: "",
          number: "",
          //detail address
        },
        items: [{ itemModel: "", description: "" }],
        deliveryDate: "",
        orderType: "SELF_COLLECT_AMK", //default form will be self collect
        //save total
        subTotal: 0,
        tax: 0,
        totalPrice: 0,
        //end save total
        timeslot: null,
        addressType: "",
        priority: "",
        distChan:"",
        deliveryCity: "",
        deliveryPostal: "",
        deliveryPhone: "",
        deliveryEmail: "",
        deliveryAddress: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryInstruction: "",
        dealer: "",
        customerPo : "",
        remarks:""
      },
      selectedIndustryType:"",
      industryTypeOption:[],
      industryTypeValid:false,
      // mandatory for Address Type "Default Address"
      mandatoryStatus: true,

      // state for Delivery Block No and Delivery Unit No fields appear or not
      doBlockandUnitFieldsState: false,

      //end of form to save data
      stockError: {},
      item: null,
      itemQty: 0,
      // warehouses: [],
      promptDraft: false,
      orderTypes: [
        { value: "SELF_COLLECT_AMK", text: "Self Collect AMK" },
        { value: "SELF_COLLECT_SUMITOMO", text: "Self Collect Sumitomo" },
        { value: "DELIVERY", text: "Delivery" },
      ],
      deliveryTypeOptions: [
        { value: "SOEC", text: "End Customer" },
        { value: "SOWH", text: "Internal Warehouse Delivery" },
      ],
      addBy: "item",
      addItemByOptions: [
        { value: "item", text: "Item" },
        { value: "bundle", text: "Bundle" },
        { value: "category", text: "Category" },
      ],
      optionAddress: ["Default Address", "New Delivery Address"],
      categoriesOptions: [],
      category: null,
      categoryItems: [],
      categoryItem: null,
      timestate: null,
      holidays: [],
      today: 0,
      deliveryDate: "",
      showBundleModal: false,
      selectedBundle: null,
      bundleQuantity:1,
      publishedItems: [],
      timeslotOptions: [],
      timeslotTemplate:[
        "10 AM to 12 noon",
        "2 PM to 5 PM",
        "6 PM onwards",
      ],
      searchItems: [],
      ajaxItem: null,
      arrayStreet: [],
      arrayBuildingName: [],
      dealerChoice: "", //This is if dealer doesn't detect at active user

      //button disable
      disableButton: false,
    };
  },
  created() {
    //get current dealer
    this.$store.dispatch("dealer/getCurrentDealer").then((x) => {
      var payload = {};
      if (this.getDealer) {
        //if user is dealer
        this.industryTypeOption= [this.getDealer.industryType];
        if(this.getDealer.secondIndustryType!=""&&this.getDealer.secondIndustryType!=undefined){
          this.industryTypeOption.push(this.getDealer.secondIndustryType)
        }
        this.selectedIndustryType= this.getDealer.industryType
        this.getItemList({
          industryType:this.selectedIndustryType
        });
      } 
      
      
    });
  },
  mounted() {
    /*
    this.getItemsForDealer().then((data) => {
      this.searchItems = data.map((distItem) => {
        //handling item catalog only show at list when status = "" (Active)
        //NEW rule, show at list when price is above 0
        if (distItem.item.status === "") {
          //parse number decimal price
          distItem.price = parseFloat(distItem.price.$numberDecimal);
          //temporary use this for better price filter by distribution channel
          return {
            label: `${distItem.item.itemModel} - ${distItem.item.description}`,
            description:distItem.item.description,
            itemModel:distItem.item.itemModel,
            ...distItem,
          };
        }
      });
    });
    */

    this.listBundles();
    this.getHolidays();

    let now = new Date();
    this.today = Number.parseInt(String(now.getDate()).padStart(2, "0"));
    console.info('today is ',this.today)

    //temporary get api customer from axios
    this.$store.dispatch("dealer/getAllDealers");

    //temporary get current user
    this.$store.dispatch("auth/fetchUser");

    this.$store.dispatch("GST/getCurrentGST");

    this.cleanOrderType();
  },
  methods: {
    ...mapActions({
      getAddressByPostal: "mapAPI/getAddressByPostal",
      addSale: "sales/addSale",
      savePurchaseDraft: "purchase/savePurchaseDraft",
      listBundles: "item/listItemBundle",
      getItemsForDealer: "item/getDealerItemToOrder",
      checkStock: "sendSAP/checkStock",
    }),
    remarksMaxHandler(){
      if(this.form.remarks.length>155){
        this.form.remarks = this.form.remarks.substring(0, 155);
      }
    },
    async addAddressByPostal(postal){
      if(postal.length!=6){
        this.$bvToast.toast(`Postal not valid`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var address;
      try{
        address = await this.getAddressByPostal(postal);
      }catch(err){
        this.$bvToast.toast(`One Map API Returns Error`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      console.info('address ',address);

      if(address.length<=0){
        this.$bvToast.toast("Postal "+postal+" has no address", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var arrayStreet=[];
      var arrayBuildingName=[];
      var arrayBlockNumber=[];
      for (let postalData of address) {
        let { ROAD_NAME,BLK_NO,BUILDING } = postalData;
        arrayStreet.push(ROAD_NAME);
        arrayBuildingName.push(BUILDING);
        arrayBlockNumber.push(BLK_NO);

      }
      let newSetArrayStreet = [...new Set(arrayStreet)];
      let newSetArrayBuildingName = [...new Set(arrayBuildingName)];
      let newSetArrayBlockNumber = [...new Set(arrayBlockNumber)];

      if (newSetArrayStreet.length > 1) {
        this.arrayStreet = newSetArrayStreet;
        this.isStreetArray = true;
      } else {
        this.form.customer.street = newSetArrayStreet[0];
        this.isStreetArray = false;
      }
      if (newSetArrayBuildingName.length > 1) {
        this.arrayBuildingName = newSetArrayBuildingName;
        this.isBuildingNameArray = true;
      } else {
        this.form.customer.buildingName = newSetArrayBuildingName[0];
        this.isBuildingNameArray = false;
      }
      if(newSetArrayBlockNumber.length==1){
        this.form.customer.block= newSetArrayBlockNumber[0];
      }

      this.$bvToast.toast(`Address found`, {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    getItemList(payload) {
      this.getItemsForDealer(payload).then((data) => {
        this.searchItems = data.map((distItem) => {
          //handling item catalog only show at list when status = "" (Active)
          //NEW rule, show at list when price is above 0
          if (distItem.item.status === "") {
            //parse number decimal price
            distItem.price = parseFloat(distItem.price.$numberDecimal);
            //temporary use this for better price filter by distribution channel
            return {
              label: `${distItem.item.itemModel} - ${distItem.item.description}`,
              description: distItem.item.description,
              itemModel: distItem.item.itemModel,
              ...distItem,
            };
          }
        });
        this.industryTypeValid=true
      }).catch((err)=>{

        let { response } = err;
        this.searchItems=[];
        this.industryTypeValid=false
        console.info('error ',payload,this.searchItems)
        
        console.info('response ',response.data.errors[0].message)
        this.$bvToast.toast(response.data.errors[0].message, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      });
    },
    formatter(value) {
      var number = value;
      if (!number) {
        number = 0;
      }
      return (Math.round(number * 100) / 100).toFixed(2);
    },
    emptyDealer() {
      this.mandatoryStatus = true;
      this.form.deliveryType = "SOEC";
      this.dealerChoice = "";
      this.chooseAddress();
      this.form.customer.address = "";
      this.form.customer.email = "";
      this.form.customer.postal = "";
      this.form.customer.phone = "";
      this.form.customer.firstName = "";
      this.form.customer.street = "";
      this.form.customer.block = "";
      this.form.customer.buildingName = "";
    },
    defineIndustryType(){
      console.log('selected industry type',this.selectedIndustryType)
      this.form.items= [{ itemModel: "", description: "" }]
      this.getItemList({
        industryType: this.selectedIndustryType,
      });
    },
    defineDealer() {
      //ini buat mencocokan item price master dilihat dari distribution channelnya apakah sama dengan industry Type dealer yg login atau dealer yang dipilih
      console.log("dealerChoice", this.dealerChoice);
      this.industryTypeOption= [this.dealerChoice.industryType]

      if(this.dealerChoice.secondIndustryType!=undefined&&this.dealerChoice.secondIndustryType!=""){
        this.industryTypeOption.push(this.dealerChoice.secondIndustryType)
      }

      this.form.items= [{ itemModel: "", description: "" }]
      this.selectedIndustryType= this.dealerChoice.industryType
      this.getItemList({
        industryType: this.selectedIndustryType,
      });
      // this.getItemList({
      //   industryType: this.dealerChoice.industryType,
      // });
      /*
      this.getItemsForDealer().then((data) => {
        this.searchItems = data.map((distItem) => {
          //handling item catalog only show at list when status = "" (Active)
          //NEW rule, show at list when price is above 0
          if (distItem.item.status === "") {
            //parse number decimal price
            distItem.price = parseFloat(distItem.price.$numberDecimal);
            //temporary use this for better price filter by distribution channel
            return {
              label: `${distItem.item.itemModel} - ${distItem.item.description}`,
              description:distItem.item.description,
              itemModel:distItem.item.itemModel,
              ...distItem,
            };
          }
        });
      });
      */
    },
    cleanUpDelivery() {
      if (
        this.form.orderType === "DELIVERY" &&
        this.form.deliveryType === "SOWH"
      ) {
        this.form.addressType = "Default Address";
        this.mandatoryStatus = false;
      } else {
        this.mandatoryStatus = true;
      }
      if (
        this.form.orderType === "DELIVERY" &&
        this.form.deliveryType === "SOEC"
      ) {
        this.form.customer = {
          type: "Individual",
          companyName: "",
          firstName: "",
          lastName: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          phone: "",
          email: "",
          postal: "",
          city: "Singapore",
          //detail address
          street: "",
          block: "",
          floor: "",
          number: "",
          //detail address
        };
      }
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        console.log("this.getDealer_Default Address", this.getDealer);
        // this.form.customer.address = this.getDealer.billingAddress1.concat(" ", this.getDealer.billingAddress2);
        this.form.customer.email = this.getDealer.dealerEmailAddress;
        this.form.customer.postal = this.getDealer.postalCode;
        this.form.customer.phone = this.getDealer.phone;
        this.form.customer.firstName = this.getDealer.dealerName;
        this.form.customer.street = this.getDealer.billingAddress1;
        this.form.customer.buildingName = this.getDealer.billingAddress2;
      } else {
        this.form.customer = {
          type: "Individual",
          companyName: "",
          firstName: "",
          lastName: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          phone: "",
          email: "",
          postal: "",
          city: "Singapore",
          //detail address
          street: "",
          block: "",
          floor: "",
          number: "",
          //detail address
        };
      }
    },
    cleanOrderType() {
      /**
      AMK
      10 Ang Mo Kio Industrial Park 2, Singapore 569501

      SUMITOMO
      value=38 Tuas West Rd, Singapore 638385
      */
     
      //reset pickedTime & timeslot
      this.form.deliveryDate=""
      this.form.timeslot=""
      if (
        this.form.orderType === "SELF_COLLECT_AMK"
      ) {
        this.form.deliveryType = "SOSC";
        this.form.customer.address = "10 Ang Mo Kio Industrial Park 2";
        this.form.customer.email = "";
        this.form.customer.postal = "569501";
        this.form.customer.phone = "";
        this.form.customer.firstName = "";
        this.form.customer.street = "10 Ang Mo Kio Industrial Park 2";
        this.form.customer.block = "";
        this.form.customer.buildingName = "";
        this.form.deliveryInstruction="Self Collect AMK";
        this.mandatoryStatus = true;
      } else if(
        this.form.orderType === "SELF_COLLECT_SUMITOMO")
      {
        this.form.deliveryType = "SOSC";
        this.form.customer.address = "38 Tuas West Rd";
        this.form.customer.email = "";
        this.form.customer.postal = "638385";
        this.form.customer.phone = "";
        this.form.customer.firstName = "";
        this.form.customer.street = "38 Tuas West Rd";
        this.form.customer.block = "";
        this.form.customer.buildingName = "";
        this.form.deliveryInstruction="Self Collect Sumitomo";
        this.mandatoryStatus = true;

      } else {
        this.form.deliveryType = "SOEC";
        this.form.deliveryInstruction="";
      }
    },
    autoFillDealer() {
      //ini buat load data waktu pilih warehouse dengan informasi detailnya
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        console.log("this.getDealer_Default Address", this.getDealer);
        this.mandatoryStatus = false;
        console.log(
          "this.mandatory_AutofillDealer(Default Address)",
          this.mandatoryStatus
        );
        this.form.customer.address = this.getDealer.billingAddress1.concat(
          " ",
          this.getDealer.billingAddress2
        );
        this.form.customer.email = this.getDealer.dealerEmailAddress;
        this.form.customer.postal = this.getDealer.postalCode;
        this.form.customer.phone = this.getDealer.phone;
        this.form.customer.firstName = this.getDealer.dealerName;
        this.form.customer.street = this.getDealer.billingAddress1;
        this.form.customer.buildingName = this.getDealer.billingAddress2;
      } else if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "New Delivery Address"
      ) {
        console.log("this.getDealer_New Delivery Address", this.getDealer);
        // this.form.customer.address =  this.form.customer.address ? this.form.customer.address : "";
        // this.form.customer.email =  this.form.customer.email ? this.form.customer.email : ""
        // this.form.customer.postal =  this.form.customer.postal ? this.form.customer.postal : ""
        // this.form.customer.phone =  this.form.customer.phone ? this.form.customer.phone : ""
        // this.form.customer.firstName = this.getDealer.dealerName
        this.form.customer.address = "";
        this.form.customer.email = "";
        this.form.customer.postal = "";
        this.form.customer.phone = "";
        this.form.customer.firstName = "";
        this.form.customer.street = "";
        this.form.customer.block = "";
        this.form.customer.buildingName = "";
        this.mandatoryStatus = true;
        
      } else {
        this.form.customer.address = this.form.customer.address
          ? this.form.customer.address
          : "";
        this.form.customer.email = this.form.customer.email
          ? this.form.customer.email
          : "";
        this.form.customer.postal = this.form.customer.postal
          ? this.form.customer.postal
          : "";
        this.form.customer.phone = this.form.customer.phone
          ? this.form.customer.phone
          : "";
        this.form.customer.firstName = this.form.customer.firstName
          ? this.form.customer.firstName
          : "";
        this.form.customer.lastName = this.form.customer.lastName
          ? this.form.customer.lastName
          : "";
        // this.mandatoryStatus = false;
        console.log("thiss", this.mandatoryStatus);
      }
      // console.log(this.form.customer)
    },
    //ini buat waktu ketik postal code kalo ada data yg tersimpan (di collection drccpostalinfos) buat auto load informasi lainnya
    autoFillAddress: lodash.debounce(function(a) {
      // console.log('this.itemDatabase', this.itemsDatabase)
      let arrayStreet = [];
      let arrayBuildingName = [];
      axios
        .get(`sales-and-purchase/drcc/postal-info`, {
          params: {
            postal: a,
          },
        })
        .then((response) => {
          let data = response.data.data;
          for (let postalData of data) {
            let { street, buildingName } = postalData;
            arrayStreet.push(street);
            arrayBuildingName.push(buildingName);
          }
          let newSetArrayStreet = [...new Set(arrayStreet)];
          let newSetArrayBuildingName = [...new Set(arrayBuildingName)];

          if (newSetArrayStreet.length > 1) {
            this.arrayStreet = newSetArrayStreet;
            this.isStreetArray = true;
          } else {
            this.form.customer.street = newSetArrayStreet[0];
            this.isStreetArray = false;
          }
          if (newSetArrayBuildingName.length > 1) {
            this.arrayBuildingName = newSetArrayBuildingName;
            this.isBuildingNameArray = true;
          } else {
            this.form.customer.buildingName = newSetArrayBuildingName[0];
            this.isBuildingNameArray = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500),
    getHolidays() {
      //ambil data hari liburan buat date picker
      axios
        .get("sales-and-purchase/holidays/range")
        .then((response) => {
          this.holidays = response.data.data.map((d) => {
            let date = new Date(d.holidayDate);
            return {
              month: date.getMonth(),
              day: Number.parseInt(String(date.getDate()).padStart(2, "0")),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addItem() {
      //buat nambahin baris item list
      if (this.addBy === "bundle") {
        for (let i of this.item.items) {
          this.form.items.push({
            model: i.itemModel,
            qty: this.itemQty,
          });
        }

        return;
      }

      this.form.items.push({
        model: this.item,
        qty: this.itemQty,
      });
    },
    addSaleBtn() {
      //ini waktu klik confirm purchase dimana akan kirim PO ke SAP. dengan sebelumnya akan check stock dulu per item
      //for handling dealer info
      
      
      this.disableButton = true;

      if (this.getDealer && this.getDealer != "") {
        //if user is dealer
        this.form.dealer = this.getDealer;
      } else {
        //if user is not dealer
        this.form.dealer = this.dealerChoice;
      }
      console.info("dealer ", this.form.dealer);

      if (
        this.form.dealer === "" ||
        this.form.dealer === null ||
        this.form.dealer === undefined
      ) {
        this.$bvToast.toast(`Please Select Dealer First`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }

      for (const [index,value] of this.form.items.entries()) {
        // validation for make sure there is at least 1 item in items list
      for (const [index,value] of this.form.items.entries()) {
        if(!value.model||value.model=="" ) {
          this.$bvToast.toast(`Please select item model on Item number `+(index+1), {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          return; 
        } 
        
        if(!value.qty||value.qty==0 ) {
          this.$bvToast.toast(`Item quantity cannot empty on Item number `+(index+1), {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          return;
        } 
      }  
      }
      /*
      if(this.form.customer.companyName == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
        this.$bvToast.toast(`Please Add Company Name`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      */

      if(this.form.customer.firstName == "" 
      && this.form.orderType === 'DELIVERY' && 
      this.form.deliveryType === 'SOEC'){
        this.$bvToast.toast(`Please Add Name`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      /*
      if(this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
        if(this.form.customer.lastName == ""){
          this.$bvToast.toast(`Please Add Last Name`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          // Prevent Any Saving Data From Run
          return;
        }
      }
      */

      if(this.form.customer.phone == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
          console.log("disable");
        this.$bvToast.toast(`Please Add Phone Number`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if(this.form.customer.email == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
          console.log("disable");
        this.$bvToast.toast(`Please Add Email`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if(this.form.customer.postal == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
          console.log("disable");
        this.$bvToast.toast(`Please Add Postal`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if(this.form.customer.street == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
          console.log("disable");
        this.$bvToast.toast(`Please Add Delivery Street Name`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      
      if(this.form.customer.block == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
          console.log("disable");
        this.$bvToast.toast(`Please Add Delivery Block No`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if(this.form.customer.buildingName == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
          console.log("disable");
        this.$bvToast.toast(`Please Add Building Name`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if(this.form.customerPo == ""){
        this.$bvToast.toast(`Please Add Customer PO`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        return;
      }

      //concatenation for address, address2(merge first and last name), address3(merge timeslot and building)
      
      //v1
      // this.form.customer.address = this.form.customer.block.concat(
      //   " ",
      //   this.form.customer.street,
      //   " #",
      //   this.form.customer.floor,
      //   "-",
      //   this.form.customer.number
      // );
      // this.form.customer.address2 = this.form.customer.firstName.concat(
      //   " ",
      //   this.form.customer.lastName
      // );
      // if (this.form.timeslot === "" || this.form.timeslot === null) {
      //   this.form.customer.address3 = this.form.customer.buildingName;
      // } else {
      //   this.form.customer.address3 = this.form.timeslot.concat(
      //     ", ",
      //     this.form.customer.buildingName
      //   );
      // }


      //v2

      this.form.customer.address = this.form.customer.block.concat(
        " ",
        this.form.customer.street
      );
      this.form.customer.address2 = (this.form.customer.floor=="")?"":"#"+this.form.customer.floor.concat(
        "-",
        this.form.customer.number,
        " ",
        this.form.customer.buildingName
      );
      if (this.form.timeslot === "" || this.form.timeslot === null) {
        this.form.customer.address3 = this.form.deliveryInstruction;
      } else {
        let timeSlotParsed=this.form.timeslot.split(":")
        this.form.customer.address3 = timeSlotParsed[0].concat(
          ", ",
          this.form.deliveryInstruction
        );
      }
      //end of concatenation

      //delivery detail value
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        this.form.deliveryCity = "";
        this.form.deliveryPostal = "";
        this.form.deliveryPhone = "";
        this.form.deliveryEmail = "";
        this.form.deliveryAddress = "";
        this.form.deliveryAddress2 = "";
        this.form.deliveryAddress3 = "";
        this.form.customer.address = "";
        this.form.customer.email = "";
        this.form.customer.postal = "";
        this.form.customer.phone = "";
        this.form.customer.firstName = "";
        this.form.customer.street = "";
        this.form.customer.block = "";
        this.form.customer.floor ="";
        this.form.customer.buildingName = "";
      } else {
        this.form.deliveryCity = this.form.customer.city;
        this.form.deliveryPostal = this.form.customer.postal;
        this.form.deliveryPhone = this.form.customer.phone;
        this.form.deliveryEmail = this.form.customer.email;
        this.form.deliveryAddress = this.form.customer.address;
        this.form.deliveryAddress2 = this.form.customer.address2;
        this.form.deliveryAddress3 = this.form.customer.address3;
      }
      //end of delivery detail value

      //condition of priority by timeslot
      if (this.form.orderType === "SELF_COLLECT_AMK") {
        this.form.priority = "03";
      } else if (this.form.orderType === "SELF_COLLECT_SUMITOMO") {
        this.form.priority = "05";
      }else if (this.form.timeslot === this.timeslotTemplate[0]) {
        this.form.priority = "11";
      } else if (this.form.timeslot === this.timeslotTemplate[1]) {
        this.form.priority = "12";
      } else if (this.form.timeslot === this.timeslotTemplate[2]) {
        this.form.priority = "13";
      } 

      //save total
      this.form.subTotal = this.cartTotal;
      this.form.tax = this.gstTotal;
      this.form.totalPrice = this.cartTotalPrice;
      //end save total

      //this industryType
      this.form.distChan = this.selectedIndustryType

      let mFormCopy = JSON.parse(JSON.stringify(this.form));

      // mFormCopy.items = mFormCopy.items.map((i) => {
      //   return Object.assign(i, { itemModel: undefined });
      // }).filter((i) => {
      //   let keys = Object.keys(i);
      //   return (keys.length - 1) > 0;
      // });

      if (mFormCopy.items.length <= 0) {
        this.$bvToast.toast("Please Add Item First", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        return;
      }
      // console.log(mFormCopy);
      this.checkStock({ items: mFormCopy.items, sloc: "FG03" })
        .then((response) => {
          this.stockError = [];

          //this is from check stock
          // console.log(response)

          this.addSale(mFormCopy)
            .then(async (response) => {
              let { data } = response;

              //ON HOLD, check respon from SAP
              // console.log(data)
              if (data.apiResult.type === "S") {
                this.$bvToast.toast("Create Sales Order Successfully", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
              } else {
                this.$bvToast.toast(data.apiResult.message, {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                });

                // this.$router.push({ name: "order" });
                this.promptDraft = true;
              }
              
              this.disableButton = false;
              await sleep(2000);
              this.$router.push({ name: "order" });
            })
            .catch(async (e) => {
              this.disableButton = false;
              console.log(e);
              if (e.response.data !== undefined) {
                this.$bvToast.toast(e.response.data.message, {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                });
              }else{
                this.$bvToast.toast("Device connection issue. Please check if your Purchase Order is created.", {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                });
              }
              await sleep(2000);
              this.$router.push({ name: "order" });
            });
        })
        .catch((e) => {
          this.disableButton=false;
          if (e.response.status === 400) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            return;
          } else {
            this.$bvToast.toast(
              "Error getting stock data, please contact administrator",
              {
                title: "Failed",
                variant: "danger",
                solid: true,
              }
            );
          }

          let { error } = e.response.data;
          this.stockError = Object.values(error);

          this.$nextTick(() => {
            // window.location.hash = '#stock-error';
            this.$refs.errorPage.focus();
          });
        })
        
    },
    saveAsDraft() {
      //ini waktu klik safe as draft. berarti simpan data di drccpurchaseorders dengan status masih draft
      //send dealer info
      //console.info('data',this.form)
      //return
      this.disableButton = true;

      if (this.getDealer && this.getDealer != "") {
        this.form.dealer = this.getDealer;
      } else {
        this.form.dealer = this.dealerChoice;
      }
      console.info("dealer ", this.form.dealer);
      if (
        this.form.dealer === "" ||
        this.form.dealer === null ||
        this.form.dealer === undefined
      ) {
        this.$bvToast.toast(`Please Select Dealer First`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      console.info('dealer form ',this.form.dealer)

      // validation for make sure there is at least 1 item in items list
      for (const [index,value] of this.form.items.entries()) {
        if(!value.model||value.model=="" ) {
          this.$bvToast.toast(`Please select item model on Item number `+(index+1), {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          this.promptDraft = false;
          return; 
        } 
        
        if(!value.qty||value.qty==0 ) {
          this.$bvToast.toast(`Item quantity cannot empty on Item number `+(index+1), {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          this.promptDraft = false;
          return;
        }
        
        
      }

      //concatenation for address, address2(merge first and last name), address3(merge timeslot and building)
      
      //v1
      // this.form.customer.address = this.form.customer.block.concat(
      //   " ",
      //   this.form.customer.street,
      //   " #",
      //   this.form.customer.floor,
      //   "-",
      //   this.form.customer.number
      // );
      // this.form.customer.address2 = this.form.customer.firstName.concat(
      //   " ",
      //   this.form.customer.lastName
      // );
      // if (this.form.timeslot === "" || this.form.timeslot === null) {
      //   this.form.customer.address3 = this.form.customer.buildingName;
      // } else {
      //   this.form.customer.address3 = this.form.timeslot.concat(
      //     ", ",
      //     this.form.customer.buildingName
      //   );
      // }


      //v2

      this.form.customer.address = this.form.customer.block.concat(
        " ",
        this.form.customer.street
      );
      this.form.customer.address2 = (this.form.customer.floor=="")?"":"#"+this.form.customer.floor.concat(
        "-",
        this.form.customer.number,
        " ",
        this.form.customer.buildingName
      );
      if (this.form.timeslot === "" || this.form.timeslot === null) {
        this.form.customer.address3 = this.form.deliveryInstruction;
      } else {
        let timeSlotParsed=this.form.timeslot.split(":")
        this.form.customer.address3 = timeSlotParsed[0].concat(
          ", ",
          this.form.deliveryInstruction
        );
      }
      //end of concatenation

      //delivery detail value
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        this.form.deliveryCity = "";
        this.form.deliveryPostal = "";
        this.form.deliveryPhone = "";
        this.form.deliveryEmail = "";
        this.form.deliveryAddress = "";
        this.form.deliveryAddress2 = "";
        this.form.deliveryAddress3 = "";
        this.form.customer.address = "";
        this.form.customer.email = "";
        this.form.customer.postal = "";
        this.form.customer.phone = "";
        this.form.customer.firstName = "";
        this.form.customer.street = "";
        this.form.customer.block = "";
        this.form.customer.floor ="";
        this.form.customer.buildingName = "";
      } else {

        if(this.form.orderType === 'SELF_COLLECT_AMK' ||
        this.form.orderType === 'SELF_COLLECT_SUMITOMO'){  
          this.form.deliveryType="SOSC"
        }
        this.form.deliveryCity = this.form.customer.city;
        this.form.deliveryPostal = this.form.customer.postal;
        this.form.deliveryPhone = this.form.customer.phone;
        this.form.deliveryEmail = this.form.customer.email;
        this.form.deliveryAddress = this.form.customer.address;
        this.form.deliveryAddress2 = this.form.customer.address2;
        this.form.deliveryAddress3 = this.form.customer.address3;
      }
      //end of delivery detail value

      //condition of priority by timeslot
      if (this.form.orderType === "SELF_COLLECT_AMK") {
        this.form.priority = "03";
      } else if (this.form.orderType === "SELF_COLLECT_SUMITOMO") {
        this.form.priority = "05";
      }else if (this.form.timeslot === this.timeslotTemplate[0]) {
        this.form.priority = "11";
      } else if (this.form.timeslot === this.timeslotTemplate[1]) {
        this.form.priority = "12";
      } else if (this.form.timeslot === this.timeslotTemplate[2]) {
        this.form.priority = "13";
      }

      //save total
      this.form.subTotal = this.cartTotal;
      this.form.tax = this.gstTotal;
      this.form.totalPrice = this.cartTotalPrice;
      //end save total
      
      //this industryType
      this.form.distChan = this.selectedIndustryType

      console.log("this.form", this.form);
      


      this.savePurchaseDraft(this.form)
        .then(async (response) => {
          this.$bvToast.toast("Sale Order Save As Draft Success", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.promptDraft = false;
          this.disableButton = false;
          await sleep(1000);

          
          // this.$router.push({ name: "order" });
        })
        .catch((e) => {
          let { response } = e;
          this.$bvToast.toast(response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.$router.push({ name: "order" });
        });
    },
    filterDisableDate(date, arrayDate) {
      let day = date.getDay()


      let epoch = moment('1970-01-01');
      let selectedDate = moment(date);
      let now = moment();

      let selectedToEpoch = selectedDate.diff(epoch,'days')
      let nowToEpoch = now.diff(epoch,'days')

      
      let currentHour = now.format("HH");
      let yesterday=now.subtract(1, 'days')
      //current hour is based on local time

      var isDelivery =(this.form.orderType=='DELIVERY')

      //console.filter disable Date
      //console.info('filterdisable date moment ',now)
      //console.info('now date ',nowDate);
      //console.info('current moment current hour ',currentHour)

      //Disable before today
      if (date <= yesterday) {
        return true;
      }


      // Disable All Sunday Days
      // No Delivery On Sunday
      if (day === 0) {
        return true;
      }

      //
      
      // Disable Delivery Today, and tommorow
      let checkedDay = Number.parseInt(String(date.getDate()).padStart(2, "0"));
      //console.info('selectedToEpoch',selectedToEpoch,nowToEpoch)
      if ((selectedToEpoch-nowToEpoch<=1)&& isDelivery) {
        return true;
      }

      //If time is already night & order type is Self Collect
      if (currentHour>17&&!isDelivery&& checkedDay == this.today){
        return true;
      }
      
      //if today is saturday & time is passed 12 pm
      if(currentHour>12&&!isDelivery&& day==6 && selectedToEpoch-nowToEpoch==0){
        return true;
      }

      // Disable Holiday Day
      let month = date.getMonth();
      let checkElem = this.holidays.filter((h) => {
        if (h.month == month && h.day == checkedDay) {
          return true;
        }

        return false;
      });
      if (checkElem.length > 0) {
        return true;
      }
      // End Disable Holiday Day

      return false;
    },
    fillItemInfoForm(index, selectedData, cartItem) {
      //ini waktu setelah milih list price dan item dan otomatis mengisi field lainnya yang dibutuhkan
      //console.info('selected Data ',selectedData);
      //console.info('cart item ',cartItem);
      var quantityTemp = !cartItem
        ? 1 //if cartItem undefined, quantity is 1
        : !cartItem.qty
        ? 1 //if cartItem.quantity undefined, quantity is 1
        : typeof cartItem.qty == "string"
        ? parseInt(cartItem.qty)
        : cartItem.qty;
      let data = Object.assign(cartItem, {
        description: selectedData.item.description,
        price: selectedData.price,
        qty: quantityTemp,
        totalPrice: quantityTemp * selectedData.price,
        itemModel: selectedData.item.itemModel,
        model: selectedData.item.itemModel,
        id: selectedData.item._id,
        channel: selectedData.channel,
      });

      this.form.items.splice(index, 1, data);
    },
    changeItemInfoQty(index, cartItem) {
      //ini otomatis perhitungan waktu qty per item diubah
      let realData = Object.assign(cartItem, {
        totalPrice: cartItem.price * cartItem.qty,
      });

      this.form.items.splice(index, 1, realData);
    },
    cartRemoveItem(index) {
      //ini buat hapus 1 baris item list detail
      this.form.items.splice(index, 1);
    },
    cartAddItem() {
      //ini buat tambah 1 baris item list detail
      this.form.items.push({});
    },
    cartAddBundle() {
      
      //ini waktu setelah keluar popup untuk pilih bundle list item, akan ada button confirm. dan dimasukkan ke list item detail pesanan
      let { items, channel } = this.selectedBundle;
      var multiplier = (typeof this.bundleQuantity !="number")?parseInt(this.bundleQuantity):bundleQuantity

      if(multiplier<=0  || typeof multiplier !="number"){
        this.$bvToast.toast(`Please Enter Valid value for Bundle Quantity`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }

      //temporary save all selected item bundle list
      let tempItems = [];
      for (let i of items) {
        tempItems.push({
          qty: i.quantity*multiplier,
          price: parseFloat(i.price.$numberDecimal),
          totalPrice: parseFloat(i.price.$numberDecimal) * 1,
          label: `${i.itemModel} - ${i.id.description}`,
          description: i.id.description,
          itemModel: i.itemModel,
          model: i.itemModel,
          channel: channel._id,
          id: i.id._id,
        });
      }

      //replace if any empty index
      var checkInside = this.form.items.map((b) => {
        return Object.keys(b).length;
      });
      for (let a = 0; a < checkInside.length; a++) {
        if (checkInside[a] === 0) {
          this.form.items.splice(a, 1);
        }
      }

      //loop by temporary item bundle list to replace form po item list.
      for (let b of tempItems) {
        let searchIndex = this.form.items.findIndex((item) => {
          return item.id === b.id;
        });
        if (searchIndex < 0) {
          this.form.items.push({
            qty: b.qty,
            price: b.price,
            totalPrice: b.price * b.qty,
            label: `${b.itemModel} - ${b.description}`,
            description: b.description,
            itemModel: b.itemModel,
            model: b.itemModel,
            channel: channel._id,
            id: b.id,
          });
        } else {
          let qtyTemp= (typeof this.form.items[searchIndex].qty == "string")?
          parseInt(this.form.items[searchIndex].qty):this.form.items[searchIndex].qty
            this.form.items[searchIndex].qty = qtyTemp + b.qty;
          this.form.items[searchIndex].totalPrice =
            this.form.items[searchIndex].price *
            this.form.items[searchIndex].qty;
        }
      }
      console.log("this.form.items", this.form.items);

      this.selectedBundle = null;
      this.bundleQuantity = 1;
      this.showBundleModal = false;
    },
    datePicked(date) {
      //ini logic buat datepicker
      // Rule 1: Delivery Or Collect Date For Today Is Disable
      // Rule 2: Delivery Can Only Happen Next Day Or The Day After
      // Rule 3: Time Slot Option Is Tied To Time When Order Created
      // Rule 4: Time Order Created After Afternoon (18:00-00:00) Timeslot Available Is Next Day Morning
      // Rule 5: Time Order Created At Morning (00:00 - 12:00) Timeslot Available Is Afternoon Slot Next Day
      // Rule 6: Saturday (Half Day ?) And Sunday Is Off Day (No Delivery Happen Here)
      // Rule 7: If Today Is Friday And Choose Delivery Date At Saturday Only Morning Slot Available

      //new rule - July 2024
      /**
       *  1. Self Collect can be processed at the same timeslot (ie. If order in Monday Morning, then self collection can also be done in Monday Morning)
          2. If order is done after 5 PM, then self collect can be done by the next morning
          3. Delivery can be processed as soon as two days after order time (ie. If order in Monday Morning, then delivery can be done as soon as Wednesday Morning)
          a. by the same rule, If customer want to order and to have the items be delivered before the week ends (Sunday), then customer have to order before Thursday Afternoon
       */

      //resetting timeslot
      this.form.timeslot="";

      let selectedTime = moment(date);
      let selectedDay = selectedTime.days();
      let selectedDate = selectedTime.date();

      let now = moment();
      let currentHour = now.format("HH");
      let currentDay = now.days();
      let currentDate = now.date();
      let isDelivery = (this.form.orderType=='DELIVERY')

      let epoch = moment('1970-01-01')
      let nowToEpoch = now.diff(epoch,'days')
      let selectedToEpoch = selectedTime.diff(epoch,'days')

      if(isDelivery){
        //for delivery
        
        console.info('selectedDate('+selectedDate+')-currentDate('+currentDate+')',selectedDate-currentDate)

        //if selected is saturday
        if(selectedDay==6){
          this.timeslotOptions=[this.timeslotTemplate[0]]
          return;
        }

        if((selectedToEpoch-nowToEpoch==2)&& currentHour>=12){
          this.timeslotOptions=[this.timeslotTemplate[1],this.timeslotTemplate[2]]
          console.info('timeslotOption delivery 12 ',this.timeslotOptions)
          return;
        }
        //
        //return all
        this.timeslotOptions=this.timeslotTemplate
        console.info('timeslotOption delivery ',this.timeslotOptions)
        return;
      }else{
        //self collect
        
        //if selected is saturday
        if(selectedDay==6){
          this.timeslotOptions=[this.timeslotTemplate[0]]
          return;
        }

        if(selectedDay==currentDay && currentHour>=12){
          this.timeslotOptions=[this.timeslotTemplate[1]]
          console.info('timeslotOption self collect 12 ',this.timeslotOptions)
          return;
        }
        //return all
        this.timeslotOptions= [this.timeslotTemplate[0],this.timeslotTemplate[1]]
        console.info('timeslotOption self collect ',this.timeslotOptions)
        return;
      }


    },
    ajaxSearch: lodash.debounce(function(search, loading) {
      loading(true);
      var currentDealer;
      if (this.getDealer && this.getDealer != "") {
        //if user is dealer
        currentDealer = this.getDealer;
      } else {
        //if user is not dealer
        currentDealer = this.dealerChoice;
      }
      var industryType = this.selectedIndustryType
      this.getItemsForDealer({
        industryType: industryType,
        item: search,
      })
        .then((data) => {
          this.searchItems = data.map((item) => {
            //handling item catalog only show at list when status = "" (Active)

            if (item.item.status === "") {
              item.price = parseFloat(item.price.$numberDecimal);
              return {
                label: `${item.item.itemModel} - ${item.item.description}`,
                description: item.item.description,
                itemModel: item.item.itemModel,
                ...item,
              };
            }
          });

          loading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500),
    ajaxItemSelected(item) {
      this.ajaxItem = item;
    },
    validateInput(event) {
      const value = event.target.value;
      // Allow all alphanumeric & special case, except -
      const regex = /-/g;
      if (regex.test(value)) {
        this.$bvToast.toast(`Inserting '-' in Customer PO is not allowed. Please use '/' instead`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.form.customerPo = value.replace('-', '');
      }
    }
  },
  computed: {
    ...mapGetters({
      itemsOptions: "item/itemsOptions",
      itemsBundles: "item/itemsBundles",
      currentGST: "GST/getCurrentGST",
    }),
    filterBundle() {
      //first step : filter all list bundle from collection by channel of dealer login
      var filterByChannel = this.itemsBundles.filter((x) => {
        return x.channel.name === this.getDealer.industryType;
      });

      //second step : search in each list bundle whether there are items that are not active from *filterByChannel* array
      //because if there is only 1 item is not active, then the bundle items are also inactive
      let findInactiveItems = filterByChannel.filter(({ items = [] }) =>
        items.some(({ id }) => id.status !== "")
      );

      //third step : Find values that are in filterByChannel but not in findInactiveItems
      var activeBundle = filterByChannel.filter(function(obj) {
        return !findInactiveItems.some(function(obj2) {
          return obj._id == obj2._id;
        });
      });

      return activeBundle;
    },
    user() {
      //buat cek informasi akun yang sedang login
      return this.$store.getters["auth/getActiveUser"];
    },
    dealer() {
      return this.$store.getters["dealer/getDealers"].filter(
        (x) => x.status === ""
      );
    },
    getDealer() {
      //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
      //kalau user bukan dealer, return undefined
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"];

      if (tempDealer === null) {
        tempDealer = this.dealerChoice;
        //this.defineDealer();
      }
      return tempDealer;
    },
    chooseAddress() {
      //ini waktu Delivery type dipilih internal warehouse dan default, sisa form tidak bisa diedit
      if (
        this.form.addressType === "Default Address" &&
        this.form.deliveryType === "SOWH"
      ) {
        return true;
      } else if(this.form.orderType === 'SELF_COLLECT_AMK' ||
        this.form.orderType === 'SELF_COLLECT_SUMITOMO'){  
          return true;
      }else{
        return false;
      }
    },
    cartTotalPrice: {
      //kalkulasi subtotal
      get() {
        return this.form.items.reduce((acc, current) => {
          if (current.totalPrice === undefined) {
            return acc;
          }

          return acc + current.totalPrice;
        }, 0);
      },
      set(val) {
        return;
      },
    },
    cartTotal() {
      //kalkulasi total price
      return this.cartTotalPrice + this.gstTotal;
    },
    gstTotal() {
      //ini nanti akan ada update GST sesuai settings. CR
      let valuePercent = this.currentGST.value / 100;
      let gstString = (
        Math.round(this.cartTotalPrice * valuePercent * 100) / 100
      ).toFixed(2);
      return Number(gstString);
    },
  },
};
</script>

<style>
.deliveryUnitNo {
  width: 100px;
}
.cart__area {
  display: flex;
  flex-direction: column;
}

.cart__area-body {
  display: grid;
  grid-template-rows: 100%;
  overflow-x: scroll;
  margin: 10px 0;
}

.cart__area-columns {
  display: grid;
  grid-template-columns: 40% 15% 15% 15% auto;
  column-gap: 15px;
  margin: 0 0 5px 0;
}

.cart__area-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cart__area-line-info {
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
}

.cart__area-line-info span {
  width: 100%;
  margin: 0 5px 0 0;
}

.cart__area-line-info input {
  width: 100px;
}

.cart-area-columns span {
  font-weight: bold;
}

.v-select .vs__dropdown-toggle {
  height: 2.714rem;
  padding: 0;
}

@media only screen and (max-width: 681px) {
  /* .halo {
    background-color: rebeccapurple;
    
  } */
  .cart__area-columns {
    display: none;
  }
}

@media only screen and (min-width: 682px) {
  /* .halo {
    background-color: red;
  } */
  .halo {
    background-color: red;
  }

  .cart__area-columns_mobile {
    display: none;
  }
}
</style>
